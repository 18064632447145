document.addEventListener('DOMContentLoaded', function () {
    const copyrightNode = document.getElementById('footerCopyright')
    const copyrightSpan = copyrightNode.querySelector('span')

    const burgerButtonNode = document.getElementById('burger')
    const mobileMenuNode = document.getElementById('mobileMenu')

    if (copyrightNode && copyrightSpan) {
        copyrightSpan.innerText = new Date().getFullYear()
    }


    if (burgerButtonNode && mobileMenuNode) {
        const setMenuPosition = () => {
            const headerHeight = document.querySelector('header').clientHeight;
            mobileMenuNode.style.top = headerHeight + 'px';
        }

        setMenuPosition()
        window.addEventListener('resize', () => {
            setMenuPosition()
        })
        burgerButtonNode.addEventListener('click', () => {
            burgerButtonNode.classList.toggle('open')
            const isOpen = burgerButtonNode.classList.contains('open')
            if (isOpen) {
                mobileMenuNode.classList.add('open')
            } else {
                mobileMenuNode.classList.remove('open')
            }
        })
    }

    function addListenerMulti(element, eventNames, listener) {
        var events = eventNames.split(' ');
        for (var i = 0, iLen = events.length; i < iLen; i++) {
            element.addEventListener(events[i], listener, false);
        }
    }

    addListenerMulti(window, 'touchmove scroll', function () {
        const burgerNode = document.getElementById('burger')
        const mobileMenuNode = document.getElementById('mobileMenu')
        if (burgerNode && burgerNode.classList.contains('open')) {
            burgerNode.classList.remove('open')
        }
        if (mobileMenuNode && mobileMenuNode.classList.contains('open')) {
            mobileMenuNode.classList.remove('open')
        }
    });
})
