document.addEventListener('DOMContentLoaded', function () {
    const headerBottom = document.getElementById('header-bottom');
    const headerBottomHeight = headerBottom.clientHeight
    const headerOrganizers = document.getElementById('header-organizers');

    if (!headerBottom || !headerOrganizers) {
        return;
    }

    const resetHeaderHeight = () => {
        headerBottom.removeAttribute('style')
    }

    resetHeaderHeight()

    window.addEventListener('resize', function () {
        resetHeaderHeight()
    })

    document.addEventListener('scroll', function () {
        headerBottom.classList.remove('active');
        headerBottom.style.height = headerBottomHeight + 'px';
    })

    const isDesktop = window.matchMedia('(min-width: 1024px)').matches;
    if (!isDesktop) {
        headerBottom.classList.remove('active');
        headerBottom.style.height = headerBottomHeight + 'px';
        return;
    }

    headerOrganizers.addEventListener('click', function () {
        const subMenu = this.querySelector('.header-nav__submenu')
        headerBottom.classList.toggle('active')
        const isActive = headerBottom.classList.contains('active')
        if (isActive) {
            headerBottom.style.height = headerBottomHeight + subMenu.clientHeight + 'px';
        } else {
            headerBottom.style.height = headerBottomHeight + 'px';
        }
    })
})
