document.addEventListener("DOMContentLoaded", function () {
  const promotionSlider = new Swiper(".promotion-slider.swiper", {
    autoplay: {
      delay: 3000,
    },
    setWrapperSize: true,
    loop: true,

    pagination: {
      el: ".swiper-pagination",
    },
    scrollbar: {
      el: ".swiper-scrollbar",
    },
  });

  const clientsSlider = new Swiper(".clients-slider.swiper", {
    slidesPerView: "auto",
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    spaceBetween: "40px",
  });

  const portfolioSliders = document
    .querySelectorAll(".portfolio-slider.swiper")
    .forEach(
      (slider) =>
        new Swiper(slider, {
          //   autoplay: {
          //     delay: 3000,
          //   },
          setWrapperSize: true,
          loop: true,
            spaceBetween: 24,

          pagination: {
            el: ".swiper-pagination",
          },
          scrollbar: {
            el: ".swiper-scrollbar",
          },
        // breakpoints: {
            //   1025: {
            //       spaceBetween: 50
            //   }
            // }
        })
    );

  // const portfolioSlider = new Swiper(".portfolio-slider.swiper", {
  // autoplay: {
  //   delay: 3000,
  // },
  // setWrapperSize: true,
  // loop: true,

  // pagination: {
  //   el: ".swiper-pagination",
  // },
  // scrollbar: {
  //   el: ".swiper-scrollbar",
  // },
  // });
});
