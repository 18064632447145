document.addEventListener('DOMContentLoaded', function () {
    const coordinates = [37.72475977342208, 55.74385303762321]

    async function initMap() {
        // Промис `ymaps3.ready` будет зарезолвлен, когда загрузятся все компоненты основного модуля API
        const mapNode = document.getElementById('map')
        if (!mapNode) {
            return
        }
        await ymaps3.ready;

        const {YMap, YMapDefaultSchemeLayer, YMapDefaultFeaturesLayer, YMapMarker} = ymaps3;

        const map = new YMap(
            document.getElementById('map'),
            {
                location: {
                    center: coordinates,
                    zoom: 15
                }
            }
        );

        map.addChild(new YMapDefaultSchemeLayer());
        map.addChild(new YMapDefaultFeaturesLayer());

        const markerElement = document.createElement('img')
        markerElement.setAttribute('style', 'width: 42px; height: 42px; margin: -21px 0 0 -21px;');
        markerElement.src = '/img/marker.png'

        map.addChild(new YMapMarker({coordinates}, markerElement));
    }

    initMap()
})
